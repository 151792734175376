/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import { gsap } from "gsap";

import { Header } from "components/Header";
import { MainMenuDecors, MainMenu } from "components/MainMenu";
import { SideLogoList } from "components/SideLogoList";
import { BannerLaunchConfigurator, BannerSignUp } from "components/Banners";
import { BodyBg } from "components/BodyBg";
import { FaqBlock } from "components/Faq";
import { AppDataContext } from "context/appData";
import { UiContext } from "context/ui";

import { Preloader } from "components/Preloader";
import { CRISP_CHAT_WEBSITE_ID, TABLET_MEDIA_POINT } from "utils/constant";
import { CrispChat } from "components/CrispChat";
import { mouseflow } from "react-mouseflow";
import * as POPUPS from "components/Popups";
import * as PAGES from "./pages";

const UiApp = () => {
  const location = useLocation();
  const { isAppDataLoaded, langApp } = useContext(AppDataContext);

  const [appGlobalAnimTimeline] = useState(
    gsap.timeline({
      paused: true,
    })
  );

  const { appPopups, isPreloaderActive, windowWidth } = useContext(UiContext);

  const onEnter = (node) => {
    gsap.from(node, {
      opacity: 0,
      ease: "power3.InOut",
      duration: 0.6,
    });
  };

  const onExit = (node) => {
    gsap.fromTo(
      node,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "power3.InOut",
        duration: 0.6,
      }
    );
  };

  useEffect(() => {
    if (isAppDataLoaded) {
      // GAVNOKOD
      let $document = document;
      let $headerInfoItem1 = $document.querySelector(
        ".header_info_item:nth-of-type(1n)"
      );
      let $headerInfoItem2 = $document.querySelector(
        ".header_info_item:nth-of-type(2n)"
      );
      let $headerInfoItem3 = $document.querySelector(
        ".header_info_item:nth-of-type(3n)"
      );
      let $headerInfoItem4 = $document.querySelector(
        ".header_info_item:nth-of-type(4n)"
      );

      appGlobalAnimTimeline
        .fromTo(
          ".header_logo",
          {
            opacity: 0,
          },
          {
            opacity: 1,
          },
          "anim"
        )
        .fromTo(
          ".side_logo_item",
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.8,
            stagger: 0.15,
          },
          "anim"
        )
        .fromTo(
          [".menu_trigger_w.open_mod", ".menu_trigger"],
          {
            x: gsap.utils.wrap(["20rem", "-20rem"]),
            y: gsap.utils.wrap(["-20rem", "20rem"]),
          },
          {
            x: 0,
            y: 0,
            duration: 0.8,
          },
          "anim"
        )
        .fromTo(
          ".main_menu_lang_item",
          {
            x: "10rem",
            y: "-10rem",
            opacity: 0,
          },
          {
            x: 0,
            y: 0,
            opacity: 1,
            stagger: -0.2,
            duration: 0.4,
          },
          "anim+=.6"
        )
        .fromTo(
          [
            $headerInfoItem2,
            $headerInfoItem1,
            $headerInfoItem4,
            $headerInfoItem3,
          ],
          {
            y: "-10rem",
            opacity: 0,
          },
          {
            x: 0,
            y: 0,
            opacity: 1,
            duration: 0.4,
            stagger: 0.4,
            ease: "power2.out",
          },
          "anim+=.6"
        );
    }
  }, [isAppDataLoaded]);

  useEffect(() => {
    if (!isPreloaderActive) {
      appGlobalAnimTimeline.play();
    }
  }, [isPreloaderActive]);

  useEffect(() => {
    if (langApp) {
      document.body.classList.add(`${langApp}_mod`);
    }
  }, [langApp]);

  return (
    <>
      {isAppDataLoaded ? (
        <>
          <CrispChat
            crispWebsiteId={CRISP_CHAT_WEBSITE_ID}
            crispRuntimeConfig={{
              locale: langApp,
            }}
          />
          <BodyBg />
          <Header />
          <MainMenuDecors />
          <MainMenu />
          {location.pathname !== "/invitation" &&
            windowWidth >= TABLET_MEDIA_POINT ? (
            <SideLogoList />
          ) : null}
          <FaqBlock />
          <BannerSignUp />
          <BannerLaunchConfigurator />
          <div className="wrapper">
            <div className="base">
              <TransitionGroup>
                <Transition
                  key={location.pathname}
                  timeout={1200}
                  onExit={onExit}
                  onEntering={onEnter}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="section_wrap">
                    <Routes location={location}>
                      <Route element={<PAGES.HomePage />} path="/" />
                      <Route element={<PAGES.InvitationPage />} path="/invitation" />
                      <Route element={<PAGES.FaqPage />} path="/faq" />
                      <Route element={<PAGES.ContactsPage />} path="/contacts" />
                      <Route element={<PAGES.TeamPage />} path="/team" />
                      <Route element={<PAGES.WhatWeOfferPage />} path="/offer" />
                      <Route element={<PAGES.ServicesPage />} path="/services" />
                      <Route element={<PAGES.ConfiguratorPage />} path="/configurator" />
                      <Route element={<PAGES.PyroguardPage />} path="/pyroguard-t" />
                      <Route element={<PAGES.LogikalPage />} path="/logikal" />
                      {/*
                      Catch-all route for 404 and redirect to home instead of
                      <Route element={<PAGES.NotFoundPage />} />
                      */}
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </div>
                </Transition>
              </TransitionGroup>
            </div>
          </div>
          <POPUPS.FaqPopup isOpen={appPopups.faq.isOpen} />
        </>
      ) : null}
      <Preloader />
    </>
  );
};

const App = () => {
  const location = useLocation();
  const rackReturnPath = `${process.env.REACT_APP_RACK_RETURN_PATH}/:id`;

  useEffect(() => {
    mouseflow.initialize(process.env.REACT_APP_MOUSEFLOW_WEBSITE_ID);
  }, []);

  return (
    <Routes location={location}>
      <Route element={<PAGES.ReturnRackPage />} path={rackReturnPath} />
      <Route path="*" element={<UiApp />} />
    </Routes>
  );
};

export default App;
