/* eslint-disable react/no-danger,camelcase */
import React,
{
	useRef,
	useContext,
	useEffect,
} from 'react';
import { AppDataContext } from 'context/appData';

import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'libs/DrawSVGPlugin';
import { detectSafariBrowser } from 'utils';

gsap.registerPlugin(DrawSVGPlugin);

const TeamPerson = ({
	name,
	quote,
	media,
	email,
	phone,
	job,
}) => {
	const { langApp } = useContext(AppDataContext);

	const $teamItem = useRef();
	const $imgOneWrap = useRef();
	const $imgSecondWrap = useRef();
	const $personVideo = useRef();
	const $name = useRef();
	const $job = useRef();
	const $email = useRef();
	const $phone = useRef();
	const $decor = useRef();
	const isSafariBrowser = useRef(detectSafariBrowser());

	const initAnim = () => {
		const elemHoverTl = gsap.timeline({
			paused: true,
			onStart: () => {
				if ($personVideo.current) {
					$personVideo.current.play();
				}
			},
			onReverseComplete: () => {
				if ($personVideo && $personVideo.current) {
					$personVideo.current.pause();
					$personVideo.current.currentTime = 0;
				}
			},
		});

		gsap.set($decor.current, {
			drawSVG: '0% 0%',
			transformOrigin: '50% 50%',
			rotate: -90,
		});

		const $list = [$job?.current, $email?.current, $phone?.current].filter(Boolean);

		elemHoverTl
			.addLabel('start')
			.fromTo($list, {
				opacity: 0,
				x: -100,
			}, {
				opacity: 1,
				x: 0,
				stagger: 0.2,
			}, 'start')
			.fromTo($imgOneWrap.current, {
				opacity: 1,
			}, {
				opacity: 0,
				duration: 0.3,
			}, 'start')
			.fromTo($imgSecondWrap.current, {
				opacity: 0,
			}, {
				opacity: 1,
				duration: 0.3,
			}, 'start+=.05')
			.fromTo($decor.current, {
				drawSVG: '0%',
			}, {
				drawSVG: '100%',
				duration: 1,
			}, 'start+=.1');

		$teamItem.current.addEventListener('mouseenter', () => {
			elemHoverTl.play();
		});

		$teamItem.current.addEventListener('mouseleave', () => {
			elemHoverTl.reverse();
		});
	};

	useEffect(() => {
		initAnim();
	}, []);

	const renderVideoTag = (hover_video) => {
		// At least one of hover_video.webm or hover_video.mov must be set
		const useMov = !hover_video.webm || isSafariBrowser.current;
		const source = useMov
			? <source src={hover_video.mov} type='video/mp4; codecs="hvc1"' />
			: <source src={hover_video.webm} type="video/webm" />;
		return (
			<div className={`team_img_wrap v2 ${useMov ? '' : 'video_mod'}`} ref={$imgSecondWrap}>
				<video className="team_img" playsInline muted ref={$personVideo}>
					{source}
				</video>
			</div>
		);
	};

	return (
		<div className="team_item" ref={$teamItem}>
			<div className="team_item_decor">
				<svg viewBox="0 0 432 432" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<circle cx="216" cy="216" r="216" fill="none" strokeWidth={1} stroke="#fff" ref={$decor} />
				</svg>
			</div>
			<div className="team_img_wrap v1" ref={$imgOneWrap}>
				{media && media.image && (
					<img className="team_img" src={media.image} alt={name || 'person'} />
				)}
			</div>
			{
				media?.hover_video && (media.hover_video.webm || media.hover_video.mov)
					? renderVideoTag(media.hover_video)
					: <div className="team_img_wrap v2" ref={$imgSecondWrap} />
			}
			<div className="team_main">
				{name && (
					<div className="team_title" ref={$name}>{name}</div>
				)}
				{job && (
					<div className="team_label" ref={$job}>{job[langApp]}</div>
				)}
				{email && (
					<a className="team_link" href={`mailto:${email}`} ref={$email}>{email}</a>
				)}
				{phone && (
					<a className="team_link" href={`tel:${phone}`} ref={$phone}>{phone}</a>
				)}
			</div>
			<div className="team_quote_wrap">
				{quote && (
					<div className="team_quote" dangerouslySetInnerHTML={{ __html: quote[langApp] }} />
				)}
			</div>
		</div>
	);
};

export default TeamPerson;
