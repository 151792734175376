import React, {
	useState, useCallback, useEffect, useRef,
} from 'react';
import GoogleMapReact from 'google-map-react';

const MapMarker = () => {
	return (
		<div className="rr_map_marker">
			<span role="img" aria-label="marker pin">📍</span>
		</div>
	);
};

const ReturnRackMap = ({ mapData, setMapData }) => {
	const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
	const MAP_DEFAULT_ZOOM_LEVEL = 8;
	const MAP_ZOOM_LEVEL_MARKER_SET = 18;
	const MAP_DEFAULT_CENTER = { lat: 47.0, lng: 8.0 };
	const [markerPosition, setMarkerPosition] = useState(mapData?.coordinates
		? { lat: mapData.coordinates.latitude, lng: mapData.coordinates.longitude }
		: null);
	const mapRef = useRef(null);

	const formatAddress = (resolvedAddress) => {
		if (!resolvedAddress) return '-';
		const parts = resolvedAddress.split(',');
		if (parts.length > 2) {
			parts.pop();
		}
		return parts.map(o => o.trim());
	};

	const setMarkerAndFetchAddressAtCoords = useCallback(async ({ lat, lng, type }) => {
		if (!MAP_API_KEY || !lat || !lng) return;
		const response = await fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API_KEY}`,
		);
		const data = await response.json();

		setMarkerPosition({ lat, lng });
		setMapData({
			resolved: true,
			locationType: type,
			coordinates: { latitude: lat, longitude: lng, accuracy: 0.0 },
			resolvedAddress: data.results[0]?.formatted_address,
			displayedAddress: formatAddress(data.results[0]?.formatted_address),
		});
	}, [MAP_API_KEY]);

	const fetchCoordinatesForAddress = useCallback(async (inputAddress) => {
		if (!MAP_API_KEY) return;
		const addr = encodeURIComponent(inputAddress.join(', '));
		if (!addr) return;
		const response = await fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${addr}&key=${MAP_API_KEY}`,
		);
		const data = await response.json();
		const firstRes = data.results[0];
		if (firstRes) {
			const { lat, lng } = firstRes.geometry.location;
			setMarkerPosition({ lat, lng });
			setMapData({
				resolved: true,
				locationType: 'address',
				coordinates: { latitude: lat, longitude: lng, accuracy: 9.99 },
				resolvedAddress: firstRes.formatted_address,
				displayedAddress: formatAddress(firstRes.formatted_address),
			});
		}
	}, [MAP_API_KEY]);

	const handleApiLoaded = (map) => {
		// Listen to map clicks to update the position
		mapRef.current = map;
		map.addListener('click', (e) => {
			const pos = { lat: e.latLng.lat(), lng: e.latLng.lng(), type: 'map-point' };
			setMarkerAndFetchAddressAtCoords(pos);
		});
	};

	useEffect(() => {
		if (!mapData) return;
		if (!mapData.coordinates && mapData.displayedAddress) {
			fetchCoordinatesForAddress(mapData.displayedAddress);
		} else if (mapData.coordinates && !mapData.displayedAddress) {
			const coords = mapData.coordinates;
			setMarkerAndFetchAddressAtCoords({ lat: coords.latitude, lng: coords.longitude, type: 'live' });
		} else if (mapRef.current) {
			const map = mapRef.current;
			const coords = mapData.coordinates;
			if (map.zoom !== MAP_ZOOM_LEVEL_MARKER_SET) {
				map.setZoom(MAP_ZOOM_LEVEL_MARKER_SET);
			}
			if (map.center.lat() !== coords.latitude || map.center.lng() !== coords.longitude) {
				map.setCenter({ lat: coords.latitude, lng: coords.longitude });
			}
		}
	}, [mapData]);

	return (
		<div className="rr_section_item rr_section_map">
			<GoogleMapReact
				bootstrapURLKeys={{ key: MAP_API_KEY }}
				defaultCenter={MAP_DEFAULT_CENTER}
				defaultZoom={MAP_DEFAULT_ZOOM_LEVEL}
				// center={markerPosition || undefined}
				// zoom={markerPosition ? MAP_ZOOM_LEVEL_MARKER_SET : undefined}
				options={(map) => ({
					mapId: 'acc786445d9ab435',
					mapTypeId: map.MapTypeId.ROADMAP,
					disableDefaultUI: true,
					clickableIcons: false,
				})}
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				yesIWantToUseGoogleMapApiInternals
			>
				{
					markerPosition && (<MapMarker lat={markerPosition.lat} lng={markerPosition.lng} />)
				}
			</GoogleMapReact>
		</div>
	);
};

export default ReturnRackMap;
