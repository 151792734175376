/* eslint-disable indent, quotes */
export const TRANSLATION_FR = {
  i18n: {
    language: "Langue",
    languageName: "Français",
  },
  rackReturn: {
    pickupLocation: "Lieu de Collecte",
    pickupStreet: "Adresse",
    pickupCity: "Lieu",
    pickupCityPlaceholder: "Lieu de Collecte",
    doTrackLocation: "✓ position",
    dontTrackLocation: "⏸ position",
    errorTrackingLocation: "✖ Erreur",
    companyName: "Entreprise",
    enterYourCompany: "Entre ton entreprise",
    contactName: "Contacte",
    enterYourName: "Entre ton nom",
    returnForRackFmt: "Formulaire de retour pour le rack n° {}",
    rackId: "Numéro du support",
    comments: "Commentaires",
    enterAnyComments: "Ajoute des commentaires",
    submit: "Envoyer",
    companyNameEmptyError: "Le nom du client est requis",
    contactNameEmptyError: "Le nom du contact est requis",
    addressEmptyError: "Adresse ou point de carte requis",
    errorSendingEmail: "⚠ Erreur lors de la soumission du formulaire",
  },
  rackReturnConfirmation: {
    thankYou: "Merci. Nous y serons sous peu ...",
  },
  rackReturnEmail: {
    subjectFmt: "Retour du rack n° {}",
    rackIdReadyAtCompanyInCityFmt: "Le rack n° {rackId} est prêt à être récupéré chez {companyName} à {city}",
    rackIdReadyInCityFmt: "Le rack n° {rackId} est prêt à être récupéré à {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Firma: <strong>{companyName}</strong></li><li>Contacte: <strong>{contactName}</strong></li><li>Adresse: <strong>{address}</strong></li><li>Langue: <strong>{language}</strong></li></ul></p><p>Commentaires:<br />{comments}</p>",
  },
};
