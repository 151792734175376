import React,
{
	useRef,
	useContext,
	useEffect,
	useState,
} from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';
import ReactHtmlParser from 'react-html-parser';

import { AppDataContext } from 'context/appData';
import { UiContext } from 'context/ui';
import { hideCrispChat, showCrispChat } from 'utils';

gsap.registerPlugin(SplitText);

const BannerLaunchConfigurator = () => {
	const { langApp, themeSettings, isSignUpBannerActive } = useContext(AppDataContext);
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
		windowHeight,
	} = useContext(UiContext);
	const [bannerData, setBannerData] = useState(null);
	const bannerLogos = useRef([]);

	const $bannerEl = useRef();
	const $titleWrapEl = useRef();
	const $title = useRef();
	const $btnWrap = useRef();
	const $btn = useRef();
	const $bgWrap = useRef();
	const $bg = useRef();
	const $logoWrap = useRef();

	const createBannerAnimation = () => {
		$title.current.innerHTML = $title.current.innerHTML.replace(/\.\s?/, '.</p><p>');
		const $titleLetters = new SplitText($title.current);

		// const
		const widthBgEl = $bgWrap.current.offsetWidth;
		const heightBgEl = $bgWrap.current.offsetHeight;

		const secondStepScalePercent = {
			width: 1.3,
			height: 0.23,
		};

		const finalStepScalePercent = {
			width: 1.9,
			height: 1.1,
		};

		const scaleXStepOne = (windowWidth * secondStepScalePercent.width) / widthBgEl;
		const scaleYStepOne = (windowHeight * secondStepScalePercent.height) / heightBgEl;

		const scaleXFinalStep = (windowWidth * finalStepScalePercent.width) / widthBgEl;
		const scaleYFinalStep = (windowHeight * finalStepScalePercent.height) / heightBgEl;

		actionBannerAnimTl
			.addLabel('onload_anim_start')
			.to($bannerEl.current, {
				opacity: 1,
				pointerEvents: 'auto',
			}, 'onload_anim_start')
			.fromTo([$bgWrap.current, $bg.current], {
				xPercent: gsap.utils.wrap([-100, 100]),
			}, {
				xPercent: 0,
				duration: 1,
				ease: 'none',
			}, 'onload_anim_start')
			.fromTo($titleLetters.chars, {
				y: 200,
				opacity: 0,
			}, {
				y: 0,
				opacity: 1,
				stagger: 0.01,
				ease: 'power2.out',
			}, 'onload_anim_start+=.4')
			.fromTo(bannerLogos.current, {
				xPercent: -50,
				yPercent: 50,
				opacity: 0,
			}, {
				xPercent: 0,
				yPercent: 0,
				duration: 0.4,
				opacity: 1,
				stagger: 0.15,
			}, 'onload_anim_start+=.6')
			.fromTo([$btnWrap.current, $btn.current], {
				xPercent: gsap.utils.wrap([-100, 100]),
			}, {
				xPercent: 0,
				duration: 0.6,
				ease: 'none',
			}, 'onload_anim_start+=1')
			.addLabel('onload_anim_end')
			.addLabel('page_transition_start')
			.to($bgWrap.current, {
				transformOrigin: '50% 96%',
				scaleX: scaleXStepOne,
				scaleY: scaleYStepOne,
				duration: 0.8,
				ease: `expoScale(1, ${scaleYStepOne})`,
			}, 'page_transition_start')
			.to([$titleWrapEl.current, $btnWrap.current, $logoWrap.current], {
				opacity: 0,
			}, 'page_transition_start')
			.to($bgWrap.current, {
				scaleX: scaleXFinalStep,
				scaleY: scaleYFinalStep,
				duration: 0.8,
				ease: `expoScale(1, ${scaleYFinalStep})`,
				onStart: () => {
					document.body.classList.add('body--animation_out');
				},
				onComplete: () => {
					gsap.delayedCall(0.4, () => {
						faqBlockAnimTl.seek('onload_anim_start');
						window.location.href = process.env.REACT_APP_CONFIGURATOR_WEBSITE;
					});
				},
			}, 'page_transition_start+=.4')
			.to('.js-global-element', {
				opacity: 0,
			}, 'page_transition_start+=.4')
			.addLabel('page_transition_end');
	};

	const handleOnClick = () => {
		hideCrispChat();

		actionBannerAnimTl.tweenFromTo('page_transition_start', 'page_transition_end', {
			onComplete: () => {
				gsap.delayedCall(1.0, () => {
					showCrispChat();
					actionBannerAnimTl.seek('page_transition_start');
				});
			},
		});
	};

	useEffect(() => {
		if (themeSettings && themeSettings.footer_banners && themeSettings.footer_banners.configurator) {
			setBannerData(themeSettings.footer_banners.configurator);
		}
	}, [themeSettings]);

	useEffect(() => {
		if (bannerData) {
			createBannerAnimation();
		}
	}, [bannerData]);

	return (
		bannerData ? (
			<button type="button" className="page_bottom_block_btn" onClick={handleOnClick}>
				<div className="page_bottom_block" ref={$bannerEl}>
					<div className="page_bottom_block_in">
						<div className="page_bottom_block_bg" ref={$bgWrap}>
							<div className="page_bottom_block_bg_in" ref={$bg} />
						</div>
						<div className="page_bottom_block_decor_w v1_mod" ref={$logoWrap}>
							{bannerData.image.image_parts && bannerData.image.image_parts.length ? (
								bannerData.image.image_parts.map(({
									url,
								}, index) => {
									return (
										<div
											className="page_bottom_block_decor"
											ref={ref => { bannerLogos.current[index] = ref; }}
											key={index}
										>
											<img className="page_bottom_block_decor_img" src={url} alt="logo" />
										</div>
									);
								})
							) : null}
						</div>
						<div className="page_bottom_block_title_w" ref={$titleWrapEl}>
							{bannerData.text && (
								<div className="page_bottom_block_title" ref={$title}>{ReactHtmlParser(bannerData.text[langApp])}</div>
							)}
						</div>
						<div className="page_bottom_block_btn_w" ref={$btnWrap}>
							<div className="page_bottom_block_btn" ref={$btn}>
								{bannerData.button && bannerData.button.title && (
									/* button is not really here but the whole page_bottom_block is wrapped in one */
									<div className="btn_base">
										<span className="btn_title">{bannerData.button.title[langApp]}</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</button>
		) : null
	);
};

export default BannerLaunchConfigurator;
