/* eslint-disable react/react-in-jsx-scope,camelcase */
const DownloadLink = ({
	uploaded_file,
	file_icon,
	title,
	name,
	type,
	size,
}) => {
	return (
		<a
			className="download_link"
			href={uploaded_file.url}
			target="_blank"
			rel="noreferrer"
		>
			<div className="download_link__icon">
				<img className="download_link__img" src={file_icon} alt="icon" />
			</div>
			<div className="download_link__content">
				<h4 className="download_link__title">{title}</h4>
				<div className="download_link__descr">{`${name} - ${type} ${size}`}</div>
			</div>
		</a>
	);
};

export default DownloadLink;
