/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import ReactHtmlParser from 'react-html-parser';

const OfferItem = ({
	url,
	title,
	subtitle,
	content,
	previewImg,
}) => {
	const { langApp } = useContext(AppDataContext);

	const renderOfferTitle = () => {
		if (title) {
			const titleWithoutLineBreaks = title[langApp].replace(/(\r\n|\n|\r)/gm, '');

			return (
				<div className="offer_item_title_w">
					<h3 className="offer_item_title">{ReactHtmlParser(titleWithoutLineBreaks)}</h3>
				</div>
			);
		}

		return null;
	};

	return (
		<div className="offer_item">
			<a className="offer_item_in" href={url.url}>
				<div className="offer_item_content_w">
					<div className="offer_item_content_front">
						{previewImg && previewImg.url ? (
							<div className="offer_item_bg_w">
								<img className="offer_item_bg" src={previewImg.url} alt={previewImg.alt} />
							</div>
						) : null}
						<div className="offer_item_decor">
							<div className="offer_item_decor_in" />
						</div>
						{renderOfferTitle()}
					</div>
					{content && (
						<div className="offer_item_content_back">
							<div className="offer_item_content_back_top">
								{subtitle && (
									<div className="offer_item_content_subtitle" dangerouslySetInnerHTML={{ __html: subtitle[langApp] }} />
								)}
								<div className="offer_item_content" dangerouslySetInnerHTML={{ __html: content[langApp] }} />
							</div>
							{renderOfferTitle()}
						</div>
					)}
				</div>
			</a>
		</div>
	);
};

export default OfferItem;
