/* eslint-disable camelcase */
import { UiContext } from 'context/ui';
import React, { useContext } from 'react';
import Slider from 'react-slick';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import OfferItem from './OfferItem';

const OfferList = ({
	offersList,
}) => {
	const { windowWidth } = useContext(UiContext);

	return (
		offersList && offersList.length ? (
			windowWidth >= TABLET_MEDIA_POINT ? (
				<div className="offer_list">
					{offersList.map(({
						offer_url,
						offer_content,
						offer_image,
						offer_title,
						offer_subtitle,
					}, index) => {
						return (
							<OfferItem
								key={index}
								url={offer_url}
								title={offer_title}
								subtitle={offer_subtitle}
								content={offer_content}
								previewImg={offer_image}
							/>
						);
					})}
				</div>
			) : (
				<Slider
					className="offer_list"
					{...{
						infinite: false,
						slidesToShow: 1,
						variableWidth: true,
						centerMode: true,
						centerPadding: 0,
						arrows: false,
						dots: true,
					}}
				>
					{offersList.map(({
						offer_url,
						offer_content,
						offer_image,
						offer_title,
						offer_subtitle,
					}, index) => {
						return (
							<OfferItem
								key={index}
								url={offer_url}
								title={offer_title}
								subtitle={offer_subtitle}
								content={offer_content}
								previewImg={offer_image}
							/>
						);
					})}
				</Slider>
			)
		) : null
	);
};

export default OfferList;
