/* eslint-disable react/no-danger */
import { AppDataContext } from 'context/appData';
import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Slider from 'react-slick';
import { SplitText } from 'libs/SplitText';
import { gsap } from 'gsap';

const HomeSliderItemInnerContentSlider = ({
	slideDescription,
	parentSlideActiveIndex,
	isHomeSliderLoadAnimationDone,
}) => {
	const { langApp } = useContext(AppDataContext);

	const [slidesAnimArray, setSlidesAnimArray] = useState([]);
	const contentSliderInstance = useRef();
	const $homeContentSliderW = useRef();
	const sliderAutoPlaySpeed = 5000;
	const [isSliderPaused, setSliderPaused] = useState(false);

	useEffect(() => {
		if (slideDescription && contentSliderInstance.current) {
			const draftslidesAnimArray = [];

			$homeContentSliderW.current.querySelectorAll('.slick-slide').forEach($slide => {
				const $mainTitle = $slide.querySelector('.homeSlideMainTitle');
				const $slideContentContainer = $slide.querySelector('.home_slider_content_container');

				let mainTitleSplitted;

				if ($mainTitle) {
					mainTitleSplitted = new SplitText($mainTitle, { type: 'words', linesClass: 'home_split_title' });
				}

				const slideInfo = {
					el: $slide,
					innerItemEl: $slideContentContainer,
					mainTitle: $mainTitle,
					mainTitleWords: mainTitleSplitted,
				};

				draftslidesAnimArray.push(slideInfo);
			});

			setSlidesAnimArray(draftslidesAnimArray);
		}
	}, [slideDescription]);

	const homeSliderAnimation = (currentSlide, nextSlide) => {
		const tlSlide = gsap.timeline();

		if (
			slidesAnimArray[currentSlide].mainTitleWords
			&& slidesAnimArray[currentSlide].mainTitleWords.words
			&& slidesAnimArray[nextSlide].mainTitleWords
			&& slidesAnimArray[nextSlide].mainTitleWords.chars
		) {
			tlSlide
				.set(slidesAnimArray[nextSlide].innerItemEl, {
					opacity: 1,
				}, 'slide')
				.fromTo(slidesAnimArray[currentSlide].mainTitleWords.words, {
					x: 0,
					opacity: 1,
				}, {
					x: '-18rem',
					opacity: 0,
					duration: 0.6,
					ease: 'power2.in',
					stagger: -0.05,
				}, 'slide')
				.fromTo(slidesAnimArray[nextSlide].mainTitleWords.words, {
					yPercent: -30,
					x: 0,
					opacity: 0,
					transformOrigin: '50% 50%',
				}, {
					yPercent: 0,
					opacity: 1,
					stagger: -0.08,
				}, 'slide+=.6')
				.set(slidesAnimArray[currentSlide].innerItemEl, {
					opacity: 0,
				});
		}
	};

	const sliderOnBeforeChange = (currentSlide, nextSlide) => {
		if (currentSlide !== nextSlide) {
			homeSliderAnimation(currentSlide, nextSlide);
		}

		return false;
	};

	const sliderOnAfterChange = currentSlide => {
		if (currentSlide === slidesAnimArray.length - 1) {
			contentSliderInstance.current.slickPause();
			setSliderPaused(true);

			setTimeout(() => {
				if (contentSliderInstance.current) {
					contentSliderInstance.current.slickGoTo(0);
				}
			}, sliderAutoPlaySpeed);
		}

		// set autoplay after first slider rotation
		if (currentSlide === 0) {
			setSliderPaused(false);
			contentSliderInstance.current.slickPlay();
		}
	};

	useEffect(() => {
		if (isHomeSliderLoadAnimationDone && contentSliderInstance.current) {
			contentSliderInstance.current.slickPlay();
		}
	}, [isHomeSliderLoadAnimationDone]);

	useEffect(() => {
		if (parentSlideActiveIndex !== 0) {
			setSliderPaused(true);
			contentSliderInstance.current.slickPause();
		}

		if (parentSlideActiveIndex === 0 && isSliderPaused) {
			setSliderPaused(false);
			contentSliderInstance.current.slickPlay();
		}
	}, [parentSlideActiveIndex]);

	useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			if (contentSliderInstance.current) {
				if (document.hidden) {
					setSliderPaused(true);
					contentSliderInstance.current.slickPause();
				} else {
					setSliderPaused(false);
					contentSliderInstance.current.slickPlay();
				}
			}
		});
	}, []);

	return (
		<div className="home_slider_content_w slider_mod" ref={$homeContentSliderW}>
			{slideDescription && slideDescription.length ? (
				<Slider
					className="home_slider_item_inner_slider_in"
					ref={contentSliderInstance}
					{...{
						infinite: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						speed: 0,
						dots: false,
						arrows: false,
						autoplay: false,
						autoplaySpeed: sliderAutoPlaySpeed,
						touchMove: false,
						waitForAnimate: false,
						pauseOnHover: false,
						accessibility: false,
						cssEase: 'none',
						beforeChange: (current, next) => sliderOnBeforeChange(current, next),
						afterChange: (current) => sliderOnAfterChange(current),
					}}
				>
					{slideDescription.map((title, index) => {
						return (
							<div className="home_slider_content_container" key={index}>
								<div className="home_slider_content_container_in">
									<div className="home_slider_content homeSlideMainTitle" dangerouslySetInnerHTML={{ __html: title[langApp] }} />
								</div>
							</div>
						);
					})}
				</Slider>
			) : null}
		</div>
	);
};

export default HomeSliderItemInnerContentSlider;
